import axios from "../utils/axiosInstance";
import Auth from "../storage/Auth";

export const searchEvent = async params => {
  try {
    const { data } = await axios.get("/api/event/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getEventById = async id => {
  try {
    const { data } = await axios.get(`/api/event/id/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getEventBySlug = async slug => {
  try {
    const { data } = await axios.get(`/api/event/slug/${slug}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createEvent = async (event) => {
  try {
    if (!(event.url && event.url.trim())) {
      event.url = event.title;
    }
    if (!(event.meta_title && event.meta_title.trim())) {
      event.meta_title = event.title;
    }
    const { data } = await axios.post("/api/event", event);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateEvent = async ({ id, event }) => {
  try {
    if (!(event.url && event.url.trim())) {
      event.url = event.title;
    }
    if (!(event.meta_title && event.meta_title.trim())) {
      event.meta_title = event.title;
    }
    const { data } = await axios.put(`/api/event/${id}`, event);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteEvent = async id => {
  try {
    const { data } = await axios.delete(`/api/event/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getEvents = async (params) => {
  try {
    const { data } = await axios.get('/api/events/get', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTreeEvents = async (payload) => {
  try {
    const { data } = await axios.get(`/api/events/tree`, {params: payload});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getParentEvents = async (parent = null) => {
  try {
    const { data } = await axios.get(`/api/events/parent/${parent}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getEventsPopular = async (parent = null) => {
  try {
    const { data } = await axios.get('/api/events/popular');
    return data;
  } catch (error) {
    throw error;
  }
};
