import { createAction, handleActions, combineActions } from 'redux-actions';
// - Actions
export const searchEventsRequest = createAction('EVENTS_LIST_SEARCH_REQUEST');
export const searchEventsSuccess = createAction('EVENTS_LIST_SEARCH_SUCCESS');

export const createEventsRequest = createAction('EVENTS_CREATE_REQUEST');
export const createEventsSuccess = createAction('EVENTS_CREATE_SUCCESS');

export const updateEventsRequest = createAction('EVENTS_UPDATE_REQUEST');
export const updateEventsSuccess = createAction('EVENTS_UPDATE_SUCCESS');

export const deleteEventsRequest = createAction('EVENTS_DELETE_REQUEST');
export const deleteEventsSuccess = createAction('EVENTS_DELETE_SUCCESS');

export const getTreeEventsRequest = createAction('GET_TREE_EVENT_REQUEST');
export const getTreeEventsSuccess = createAction('GET_TREE_EVENT_SUCCESS');

export const getParentEventsRequest = createAction('GET_PARENT_EVENT_REQUEST');
export const getParentEventsSuccess = createAction('GET_PARENT_EVENT_SUCCESS');

export const getEventsPopularRequest = createAction('GET_EVENT_POPULAR_REQUEST');
export const getEventsPopularSuccess = createAction('GET_EVENT_POPULAR_SUCCESS');

export const getEventsRequest = createAction('GET_EVENT_REQUEST');
export const getEventsSuccess = createAction('GET_EVENT_SUCCESS');

export const getEventByIdRequest = createAction('GET_EVENT_BY_ID_REQUEST');
export const getEventByIdSuccess = createAction('GET_EVENT_BY_ID_SUCCESS');

export const failure = createAction('EVENTS_ACTION_FAILURE');


// - Initial State
export const initialState = {
  events: [],
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
  loading: false,
  event: null,

  showMessage: false,
  parentEvents: [],
  loadingFetch: false,
  
  mainEvents: [],
  mainLoading: false,
  
  trees: [],
  treesing: false,
  
  populars: [],
  popularsing: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [combineActions(createEventsRequest, deleteEventsRequest, updateEventsRequest, searchEventsRequest)]: (state) => ({
    ...state, loading: true
  }),
  [searchEventsSuccess]: (state, { payload: { events, pagination } }) => {
    return { ...state, events: events, pagination, loading: false };
  },
  [combineActions(createEventsSuccess, deleteEventsSuccess, updateEventsSuccess)]: (state) => ({
    ...state, loading: false
  }),

  [getTreeEventsRequest]: (state) => {
    return { ...state, trees: [], treesing: true };
  },
  [getTreeEventsSuccess]: (state, { payload: { trees } }) => {
    return { ...state, trees: trees, treesing: false };
  },

  [getParentEventsRequest]: (state) => {
    return { ...state, parentEvents: [], loadingFetch: true };
  },
  [getParentEventsSuccess]: (state, { payload: { events } }) => {
    return { ...state, parentEvents: events, loadingFetch: false };
  },

  [getEventsPopularRequest]: (state) => {
    return { ...state, populars: [], popularsing: true };
  },
  [getEventsPopularSuccess]: (state, { payload: { events } }) => {
    return { ...state, populars: events, popularsing: false };
  },

  [getEventsRequest]: (state) => {
    return { ...state, mainEvents: [], mainLoading: true };
  },
  [getEventsSuccess]: (state, { payload: { events } }) => {
    return { ...state, mainEvents: events, mainLoading: false };
  },

  [getEventByIdRequest]: (state) => {
    return { ...state, event: null};
  },
  [getEventByIdSuccess]: (state, { payload: { event } }) => {
    return { ...state, event: event };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
