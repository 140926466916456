import { call, put, takeLatest } from 'redux-saga/effects';
import * as eventService from '../../services/event-service';
import { EventFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  deleteEventsSuccess,
  searchEventsRequest,
  searchEventsSuccess,
  updateEventsSuccess,
  getTreeEventsSuccess,
  getParentEventsSuccess,
  getEventsPopularSuccess,
  getEventsSuccess,
  getEventByIdSuccess,
  failure,
  getTreeEventsRequest,
} from './reducer';


export function* fetchEvents({ payload = {} }) {
  try {
    const { data } = yield call(eventService.searchEvent, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchEventsSuccess({ events: results, pagination }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* updateEvents({ payload }) {
  try {
    const result = yield call(eventService.updateEvent, payload);
    if (result) {
      yield put(updateEventsSuccess());
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createEvents({ payload }) {
  try {
      let result;
      if (payload.id) {
        result = yield call(eventService.updateEvent, { event: payload, id: payload.id});
      } else {
        result = yield call(eventService.createEvent, payload);
      }
    if (result) yield put(EventFormAction.success('app.editSuccess'))
    else {
      const message = 'Add photo failed'
      const formError = new SubmissionError({
        _error: message
      })

      yield put(EventFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(EventFormAction.failure(formError))
  }
}

export function* deleteEvents({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(eventService.deleteEvent, id);
    if (result) {
      yield put(searchEventsRequest());
      yield put(deleteEventsSuccess());
      if(cb) cb('success');
    }
  } catch (error) {
    yield put(failure(error));
    if(cb) cb('error');
  }
}

export function* getTreeEvents({ payload = {} }) {
  try {
    const { data } = yield call(eventService.getTreeEvents, payload);
    yield put(getTreeEventsSuccess({ trees: data || [] }));
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getParentEvents({ payload = {} }) {
  try {
    const { id } = payload;
    const { data } = yield call(eventService.getParentEvents, id);
    yield put(getParentEventsSuccess({ events: data || [] }));
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getEventsPopular({ payload = {} }) {
  try {
    const { data } = yield call(eventService.getEventsPopular);
    if(data && data.results) {
      const { results } = data;
      yield put(getEventsPopularSuccess({ events: results }));
    } else {
      yield put(getEventsPopularSuccess({ events: [] }));
    }
  } catch (error) {
    yield put(getEventsPopularSuccess({ events: [] }));
  }
}

export function* getEvents({ payload = {} }) {
  try {
    const { data } = yield call(eventService.getEvents, payload);
    if(data && data.events) {
      yield put(getEventsSuccess({ events: data.events }));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* getEventById({ payload = {} }) {
  try {
    const { data } = yield call(eventService.getEventById, payload.id);
    if(data && data._id) {
      yield put(getEventByIdSuccess({ event: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export default [
  takeLatest(EventFormAction.REQUEST, createEvents),
  takeLatest('EVENTS_LIST_SEARCH_REQUEST', fetchEvents),
  takeLatest('EVENTS_DELETE_REQUEST', deleteEvents),
  takeLatest('EVENTS_UPDATE_REQUEST', updateEvents),
  takeLatest('GET_TREE_EVENT_REQUEST', getTreeEvents),
  takeLatest('GET_PARENT_EVENT_REQUEST', getParentEvents),
  takeLatest('GET_EVENT_POPULAR_REQUEST', getEventsPopular),
  takeLatest('GET_EVENT_REQUEST', getEvents),
  takeLatest('GET_EVENT_BY_ID_REQUEST', getEventById),
];
