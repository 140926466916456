import { all } from "redux-saga/effects";
import authSaga from "./auth-redux/saga";
import commonSaga from './common-redux/saga';
import profileSaga from "./profile-redux/saga";
import categorySaga from "./category-redux/saga";
import feedbackSaga from "./feedback-redux/saga";
import guideSaga from "./guide-redux/saga";
import eventSaga from "./event-redux/saga";
import topicSaga from "./topic-redux/saga";
import articleSaga from "./article-redux/saga";
import flashdealSaga from "./flashdeal-redux/saga";
import sliderSaga from "./slider-redux/saga";
import setupSaga from "./setup-redux/saga";
import priceSaga from "./price-redux/saga";
import sytemSaga from "./system-redux/saga";
import contactSaga from "./contact-redux/saga";
import usersSaga from "./users-redux/saga";
import imageSaga from './image-redux/saga';
import keywordSaga from './keyword-redux/saga';
import productSaga from './product-redux/saga';
import sizeSaga from './size-redux/saga';
import unitSaga from './unit-redux/saga';
import originSaga from './origin-redux/saga';
import brandSaga from './brand-redux/saga';
import sellerSaga from './seller-redux/saga';
import colorSaga from './color-redux/saga';
import materialSaga from './material-redux/saga';

export default function* rootSaga(getState) {
  yield all([
    ...authSaga,
    ...commonSaga,
    ...profileSaga,
    ...categorySaga,
    ...feedbackSaga,
    ...guideSaga,
    ...eventSaga,
    ...topicSaga,
    ...articleSaga,
    ...flashdealSaga,
    ...sliderSaga,
    ...setupSaga,
    ...priceSaga,
    ...sytemSaga,
    ...contactSaga,
    ...usersSaga,
    ...imageSaga,
    ...keywordSaga,
    ...productSaga,
    ...sizeSaga,
    ...unitSaga,
    ...originSaga,
    ...brandSaga,
    ...sellerSaga,
    ...colorSaga,
    ...materialSaga,
  ]);
}
