import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import authReducer from './auth-redux/reducer';
import commonReducer from './common-redux/reducer';
import profileReducer from './profile-redux/reducer';
import language from './language-redux/reducer';
import categoryReducer from "./category-redux/reducer";
import feedbackReducer from "./feedback-redux/reducer";
import guideReducer from "./guide-redux/reducer";
import eventReducer from "./event-redux/reducer";
import flashdealReducer from "./flashdeal-redux/reducer";
import topicReducer from "./topic-redux/reducer";
import articleReducer from "./article-redux/reducer";
import contactReducer from "./contact-redux/reducer";
import priceReducer from "./price-redux/reducer";
import systemReducer from './system-redux/reducer';
import sliderReducer from './slider-redux/reducer';
import setupReducer from './setup-redux/reducer';
import usersReducer from "./users-redux/reducer";
import imageReducer from './image-redux/reducer';
import keywordReducer from './keyword-redux/reducer';
import productReducer from './product-redux/reducer';
import sizeReducer from './size-redux/reducer';
import unitReducer from './unit-redux/reducer';
import originReducer from './origin-redux/reducer';
import brandReducer from './brand-redux/reducer';
import sellerReducer from './seller-redux/reducer';
import colorReducer from './color-redux/reducer';
import materialReducer from './material-redux/reducer';

export default (history, injectedReducers) => combineReducers({
    // routing: routerReducer,
    form: formReducer,
    router: connectRouter(history),
    auth: authReducer,
    common: commonReducer,
    profile: profileReducer,
    systemLanguage: language,
    guide: guideReducer,
    event: eventReducer,
    flashdeal: flashdealReducer,
    topic: topicReducer,
    category: categoryReducer,
    feedback: feedbackReducer,
    article: articleReducer,
    slider: sliderReducer,
    setup: setupReducer,
    price: priceReducer,
    system: systemReducer,
    contact: contactReducer,
    users: usersReducer,
    image: imageReducer,
    keyword: keywordReducer,
    product: productReducer,
    size: sizeReducer,
    unit: unitReducer,
    origin: originReducer,
    brand: brandReducer,
    seller: sellerReducer,
    color: colorReducer,
    material: materialReducer,
    ...injectedReducers,
  });
